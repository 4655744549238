import { gtag } from '../ga-loader';
import { getGA4TrackingId, getGTMTrackingId , getLoggedInUserData } from '../helpers';
import { country } from '../constants';

const validGA4Events = [
  {
    name: 'view_item_list',
  },
  {
    name: 'view_item',
  },
  {
    name: 'select_content',
  },
  {
    name: 'checkout_progress',
  },
  {
    name: 'add_to_cart',
  },
  {
    name: 'remove_from_cart',
  },
  {
    event_action: 'add_to_wishlist',
  },
  {
    name: 'begin_checkout',
  },
  {
    name: 'purchase',
  },
  {
    event_action: 'logins_ikea_family',
  },
  {
    event_action: 'view_list',
  },
  {
    name: 'set_checkout_option',
  },
  {
    event_action: 'signup_begun_ikea_family',
  },
  {
    event_action: 'open_loyalty_hub',
  },
  {
    event_action: 'wishlist_increase_amount',
  },
  {
    event_action: 'wishlist_decrease_amount',
  },
  {
    event_action: 'remove_and_wishlist_unavailable_products',
  },
  {
    event_action: 'sign_up',
  },
  {
    event_action: 'remove_from_wishlist',
  },
  {
    event_action: 'wishlist_store_mode',
  },
  {
    event_action: 'logins_regular_profile',
  },
  {
    event_action: 'remove_unavailable_products',
  },
  // Additional events added from the below
   {
    event_category: 'clean_energy_services',
    event_action: 'click',
  },
  {
    event_category: 'clean_energy_services',
    event_action: 'conversion_funnel',
  },
  {
    event_category: 'contact',
    event_action: 'phone',
  },
  {
    event_category: 'contact',
    event_action: 'email',
  },
  {
    event_category: 'outbound_link',
  },
  {
    event_category: 'pub_page_actions',
    event_action: 'cta_click',
  },
  {
    event_category: 'self_serve_actions',
    event_action: 'appointment',
  },
  {
    event_category: 'local_tracking_b2b_booking_tool',
  },
  {
    event_category: 'local_tracking_kitchen_booking_tool',
  },
  //search events
  {
    event_category: 'search',
    event_action: 'hard_search',
  },
  {
    event_category: 'search',
    event_action: 'product_recent',
  },
  {
    event_category: 'search',
    event_action: 'product_suggest',
  },
  {
    event_category: 'search',
    event_action: 'category_suggest',
  },
  {
    event_category: 'search',
    event_action: 'related_searches',
  },
  {
    event_category: 'search',
    event_action: 'did_you_mean',
  },
  {
    event_category: 'search',
    event_action: 'content_suggest',
  },
  {
    event_category: 'search',
    event_action: 'category_popular',
  },
  {
    event_category: 'search',
    event_action: 'planner_suggest',
  },
  {
    event_category: 'search',
    event_action: 'visual_searches',
  },
  {
    event_category: 'search',
    event_action: 'cancel_autocorrect',
  },
  {
    event_category: 'search',
    event_action: 'store_suggest',
  },
  {
    event_category: 'search_page_actions',
  },
  {
    event_category: 'search_backend',
  },
  {
    event_category: 'search_box_actions',
  },
  //optimizely ga4 event
  {
    event_category: 'optimizely',
    event_action: 'user bucketed',
  },
  //spain market additional event
  {
    event_category: 'video',
    event_action: 'start',
  },
  {
    event_category: "cart",
    event_action: "create_checkout_redirect",
  },
];

const allowed_fcapi_countries = ['be','se', 'ch', 'dk']; // Add all allowed country codes here (by order of addition)
const allowed_fcapi_events = ['purchase', 'add_to_cart', 'add_to_wishlist', 'view_item', 'hard_search', 'appointment', 'begin_checkout', 'create_checkout_redirect'];
const fcapi_enabled = allowed_fcapi_countries.includes(country);
let party_uid, token_value;

try {
  // CAPI markets need party_uid on page_view and allowed events. Retrieval needs to be done on config() and dispatch() outer scope.
  if (fcapi_enabled) {
    ({ party_uid, token_value } = await getLoggedInUserData());
  }
} catch (ex) {
  console.error('GA4 FCAPI error: ' + ex);
}

export const config = (config) => {
  const trackingId = getGA4TrackingId();

  if (trackingId) {
    gtag('config', trackingId, {
      ...config,
      groups: 'ga4',
    })
  }

  const gtmTrackingId = getGTMTrackingId();

  if (gtmTrackingId) {
    let targeting_cookies;

    if (window.ikea && window.ikea.cookieConsent && window.ikea.cookieConsent.hasConsent) {
      targeting_cookies=ikea.cookieConsent.hasConsent(4);
    }

    gtag('config', gtmTrackingId, {
      ...config,
      transport_url: 'https://sgtm.ikea.com',
      first_party_collection: true,
      send_page_view: false,
      groups: 'ssga4',
      targeting_cookies,
    });

    const { page_location, page_title, page_path, client_event_id } = config;

    let party_uid_ep, auth_token_ep;
    if (allowed_fcapi_countries.includes(country)) {
      party_uid_ep = party_uid;
      auth_token_ep = token_value;
    }

    gtag('event', 'page_view', {
      page_title,
      page_path,
      page_location,
      client_event_id,
      party_uid_ep,
      auth_token_ep,
      send_to: 'ssga4',
    });
  }
};

const transformEventObj = (eventObj) => {
  const {
    event_label,
    event_action,
    name,
    custom = {},
  } = eventObj;

  if (name === 'select_content' || event_action === 'select_content') {
    return Object.assign({ ...eventObj }, { name: 'select_item'});
  }

  if (name === 'checkout_progress' || event_action === 'checkout_progress') {
    if (event_label && event_label.includes('delivery selected')) {
      return Object.assign({ ...eventObj }, {
        name: 'add_shipping_info',
        event_action: 'add_shipping_info',
        custom: Object.assign({ ...custom }, { shipping_tier: custom.order_type_detailed }),
})
    }

    return null;
  }

  if (name === 'set_checkout_option' || event_action === 'set_checkout_option') {
    if (event_label && event_label.includes('payment selected')) {
      return Object.assign({ ...eventObj }, {
        event_action: 'add_payment_info',
        custom: Object.assign({ ...custom }, { payment_type: custom.payment_method }),
      })
    }

    return null;
  }

  return eventObj;
};

const isValidGA4Event = (object, filters) => {
  const found = filters.find(filter => {
    let valid = true;
    for (const [key, value] of Object.entries(filter)) {
      valid = valid && object[key] === value;
    }
    return valid;
  });

  return !!found;
}


export const dispatch = async (eventObj = {}) => {
  const trackingId = getGA4TrackingId();
  const gtmTrackingId = getGTMTrackingId();

  if (!trackingId && !gtmTrackingId) {
    return;
  }

  const isValid = isValidGA4Event(eventObj,validGA4Events);

  if (!isValid) {
    return;
  }

  const transformedEvent = transformEventObj(eventObj);

  if (!transformedEvent) {
    return;
  }

  const {
    event_category,
    event_action,
    event_label,
    name,
    params,
    value,
    page_location,
    page_path,
    client_event_id,
    custom = {},
  } = transformedEvent;



  if (trackingId) {
    event_category
      ? gtag('event', event_action, {
        type: event_category,
        page_location,
        page_path,
        value,
        client_event_id,
        ...custom,
        send_to: 'ga4',
      })
      : gtag('event', name, {
        page_location,
        page_path,
        client_event_id,
        ...params,
        ...custom,
        send_to: 'ga4',
      });
  }

  if (gtmTrackingId) {

    let targeting_cookies, party_uid_ep, auth_token_ep;
    let capi_event_name = event_action || name;
    if (allowed_fcapi_events.includes(capi_event_name) && fcapi_enabled) {
      party_uid_ep = party_uid;
      auth_token_ep = token_value;
    }


  if (window.ikea && window.ikea.cookieConsent && window.ikea.cookieConsent.hasConsent) {
    targeting_cookies = ikea.cookieConsent.hasConsent(4);
  }
  event_category
    ? gtag('event', event_action, {
      type: event_category,
      label: event_label,
      page_location,
      page_path,
      value,
      ...custom,
      targeting_cookies,
      party_uid_ep,
      auth_token_ep,
      client_event_id,
      send_to: 'ssga4',
    })
    : gtag('event', name, {
      page_location,
      page_path,
      label: event_label,
      ...params,
      ...custom,
      targeting_cookies,
      party_uid_ep,
      auth_token_ep,
      client_event_id,
      send_to: 'ssga4',
    });

 }
};
