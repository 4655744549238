import { init } from '@ingka-group-digital/episod-client-js';
import { country, language, PROD } from '../constants';
import {
  getContentSquareMatchingKey,
  getLoggedInUserData,
  getSessionID as getPageviewId,
} from '../helpers';
import {
  getCookieConsent,
  isCookieConsentCountry,
  getPostalCode,
  getStoreId,
  getGeoCountry,
} from './helpers';

export const initEpisod = async () => {
  const cookieDomain = PROD ? '.ikea.com' : '.ikeadt.com';
  const url = PROD
    ? `https://web-api.ikea.com/episod/collect`
    : 'https://web-api.cte.ikeadt.com/episod/collect';
  const xClientId = PROD
    ? '1ea83eeb-f16f-43d0-a11d-2487f62fb456'
    : 'a0bb26de-64e2-4000-8b31-076cdb84c29d';

  const optimizelyVisitorId = document.cookie
    .split('; ')
    .find(row => row.startsWith(`ikexp_id=`))
    ?.split('=')[1];

  const { party_uid, customer_type } = await getLoggedInUserData();
  const consent = isCookieConsentCountry(country) ? getCookieConsent : null;
  const commons = {
    pageview_id: getPageviewId,
    market_code: country,
    event_origin: 'web',
    event_details: {
      postal_code: getPostalCode,
      store_id: getStoreId,
      location_language: language,
      optimizely_visitor_id: optimizelyVisitorId,
    },
    client_details: {
      cs_key: getContentSquareMatchingKey(),
      consent,
      party_uid,
      customer_type,
      geo_location_country: getGeoCountry,
    },
  };

  init({
    cookieDomain,
    url,
    xClientId,
    commons,
  });
};
